<template>
  <div class="cloud">
    <!-- 主要板块 -->
    <div class="main-container" oncontextmenu="return false;">
      <ShadowCard
        :cover-src="bigDataIMG.bg_exhibition"
        cover-width="460px"
        cover-height="280px"
        clickable
        @click.native="toPage('CloudExhibitionList')"
        >
        <template #content>
          <div class="card-content">
            <img :src="bigDataIMG.i_exhibition" alt="" draggable="false" class="icon" />
            <div class="text">云观展</div>
          </div>
        </template>
      </ShadowCard>
      <ShadowCard
        :cover-src="bigDataIMG.bg_live"
        cover-width="460px"
        cover-height="280px"
        clickable
        @click.native="toPage('CloudLiveList')"
      >
        <template #content>
          <div class="card-content">
            <img :src="bigDataIMG.i_live" alt="" draggable="false" class="icon" />
            <div class="text">云直播</div>
          </div>
        </template>
      </ShadowCard>
    </div>
    <!-- 展会服务板块 -->
    <div v-if="serviceList && serviceList.length" class="service-container" oncontextmenu="return false;">
      <div class="header">
        <img :src="bigDataIMG.i_service" alt="" draggable="false" class="icon" />
        <div class="text">展会服务</div>
      </div>
      <div class="list">
        <ShadowCard
          v-for="{ id, image, url } in serviceList"
          :key="id"
          :cover-src="image"
          cover-width="250px"
          cover-height="146px"
          clickable
          @click.native="toUrlPage(url)"
        />
      </div>
    </div>
    <!-- 小程序板块 -->
    <div class="mini-program-container" oncontextmenu="return false;">
      <div class="header">
        <img :src="bigDataIMG.i_transfer" alt="" draggable="false" class="icon" />
        <div class="text">云交易</div>
      </div>
      <div class="list">
        <ShadowCard
          :cover-src="bigDataIMG.btn_shop"
          cover-width="249px"
          cover-height="132px"
          :clickable="true"
          @click.native="showQrcode('shop')"
        />
        <ShadowCard
          :cover-src="bigDataIMG.btn_app"
          cover-width="249px"
          cover-height="132px"
          :clickable="true"
          @click.native="showQrcode('app')"
        />
        <ShadowCard
          :cover-src="bigDataIMG.btn_shopping_guide"
          cover-width="249px"
          cover-height="132px"
          :clickable="true"
          @click.native="toShoppingGuideIndexPage"
        />
        <ShadowCard
          :cover-src="bigDataIMG.btn_exhibitor"
          cover-width="249px"
          cover-height="132px"
          :clickable="true"
          @click.native="showQrcode('exhibitor')"
        />
      </div>
    </div>
    <!-- 云资讯板块 -->
    <div v-if="newsTotal" class="news-container" oncontextmenu="return false;">
      <div class="header">
        <img :src="bigDataIMG.i_news" alt="" draggable="false" class="icon" />
        <div class="text">云资讯</div>
        <div class="button" @click="toPage('CloudNewsList')">
          <span>更多</span>
          <i class="el-icon-arrow-right" />
        </div>
      </div>
      <div v-if="newsList.length >= 4" class="list">
        <div class="left">
          <CloudNewsCard :news="newsList[0]" />
        </div>
        <div class="right">
          <CloudNewsCard
          v-for="item in newsList.slice(1, 4)"
          :key="item.id"
          :news="item"
          :is-show-cover="false"
          />
        </div>
      </div>
      <div v-else class="short-list">
        <CloudNewsCard
          v-for="item in newsList"
          :key="item.id"
          :news="item"
          :is-show-cover="false"
          width="1212px"
          />
      </div>
    </div>
    <!-- 云资讯板块 -->
    <div class="review-container" oncontextmenu="return false;">
      <div class="header">
        <img :src="bigDataIMG.i_review" alt="" draggable="false" class="icon" />
        <div class="text">往期回顾</div>
      </div>
      <div class="list">
        <ShadowCard
          :cover-src="bigDataIMG.btn_review_exhibition"
          cover-width="344px"
          cover-height="138px"
          :clickable="true"
          @click.native="toPage('CloudExhibitionList', { year: '2022' })"
        />
        <ShadowCard
          :cover-src="bigDataIMG.btn_review_live"
          cover-width="344px"
          cover-height="138px"
          :clickable="true"
          @click.native="toPage('CloudLiveList', { year: '2022' })"
        />
      </div>
    </div>

    <!-- 小程序码对话框 -->
    <qrcode-dialog
      :dialog-visible="qrcodeDialogVisible"
      :type="qrcodeType"
      @close="handleCloseDialog"
    />
  </div>
</template>

<script>
import { getServiceList, NewsList } from '../../api'
import ShadowCard from '@/components/ShadowCard.vue'
import CloudNewsCard from '@/components/CloudNewsCard.vue'
import QrcodeDialog from '@/components/QrcodeDialog.vue'
import imgSrc from "@/utils/bigDataIMG.js"
import { MINI_PROGRAM_PAGE_LIST } from '@/utils/constant'
import { webviewUrlHandler } from '@/utils/url'
const bigDataIMG = imgSrc.data()
const IMG_DOMAIN = 'http://img.miaocang.cc/'

export default {
  name: 'Cloud',

  components: { ShadowCard, CloudNewsCard, QrcodeDialog },

  data() {
    return {
      // 展会服务图片列表数据
      serviceList: [],
      // 云资讯列表数据
      newsList: [],
      newsTotal: 0,
      // 云资讯列表请求参数
      newsListQuery: {
        // 云资讯类型的新闻, 默认newsType为'3'
			  newsType: '3',
        pageNum: 1,
			  pageSize: 4
      },
      // 二维码对话框可见性
      qrcodeDialogVisible: false,
      // 需要显示的二维码类型
      qrcodeType: '',
      bigDataIMG
    }
  },

  created() {
    Promise.all([
      this.getServiceListData(),
      this.getNewsListData()
    ])
  },

  methods: {
    /* 发送请求, 获取展会服务列表数据 */
    getServiceListData() {
      const miniProgramPageUrlList = MINI_PROGRAM_PAGE_LIST.map(item => item.url)
      return getServiceList().then(res => {
        const list = res.data
        // 展会服务列表的第一项, 为云展会小程序服务页的顶部logo, 需要去掉
        list.shift()
        this.serviceList = list
      }).catch(error => {
        this.serviceList = []
        console.log('getServiceListData', error)
      })
    },

    /* 发送请求, 获取云资讯列表数据 */
    getNewsListData() {
      return NewsList(this.newsListQuery).then(res => {
        const { content: list, total } = res.data
        this.newsTotal = total
        this.newsList = list.map(item => {
          const { publishedTime, newsCover, viewCount, collectCount } = item
          return {
            ...item,
            newsCover: newsCover.startsWith('http') ? newsCover : `${IMG_DOMAIN}${newsCover}`,
            // 发布时间(省略秒)
            publishedTimeTxt: publishedTime ? `${publishedTime.substring(0, publishedTime.length - 3)} 发布` : '-',
            // 阅读量
            // viewsTxt: `${~~(Math.random() * 10000)}阅读`,
            viewCountTxt: `${viewCount}阅读`,
            // 收藏量
            // favoritesTxt: `${~~(Math.random() * 10000)}收藏`,
            collectCountTxt: `${collectCount}收藏`
          }
        })
      }).catch(error => {
        this.newsTotal = 0
        this.newsList = []
        console.log('getNewsListData', error)
      })
    },
    
    /* 跳转到对应route name页面 */
    toPage(name, query) {
      this.$router.push({
        name,
        ...(query && {
          query
        })
      })
    },

    /* 打开url */
    toUrlPage(url = '') {
      // 先判断url是否为后台配置的小程序页面
      const miniProgramPage = MINI_PROGRAM_PAGE_LIST.find(item => url.includes(item.url))
      if(!miniProgramPage) {
        url && window.open(webviewUrlHandler(url))
      } else {
        this.$router.push({
          name: miniProgramPage.name
        })
      }
    },

    /* 打开二维码 */
    showQrcode(type = '') {
      this.qrcodeDialogVisible = true
      this.qrcodeType = type
    },

    /* 对话框关闭的事件监听处理 */
    handleCloseDialog() {
      this.qrcodeDialogVisible = false
      this.qrcodeType = ''
    },

    /* 浏览器新标签页打开花木产业导购图 H5 页 */
    toShoppingGuideIndexPage() {
      window.open(`${location.protocol}//${location.host}/shopping-guide/index`, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.cloud {
  width: 1280px;
  min-height: 20vh;
  margin: 0 auto;
  .shadow-card {
    margin: 0 33px;
  }
  .main-container, .list {
    display: flex;
    min-width: 1280px;
    justify-content: center;
    margin: 40px 0;
  }
  .card-content, .header {
    position: relative;
    display: flex;
    justify-content: center;
    height: 33px;
    padding: 16px 24px;
    font-size: 24px;
    line-height: 33px;
    user-select: none;
    .text {
      font-weight: 600;
      color: #177D6A;
    }
    .icon {
      width: 30px;
      height: 30px;
      margin-right: 3px;
    }
    .button {
      position: absolute;
      top: calc(50% - 20px / 2);
      right: 24px;
      width: 48px;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #999;
      &:hover, i:hover {
        cursor: pointer;
        color: #177D6A;
      }
    }
  }
  .news-container 
    .list {
      display: flex;
      justify-content: center;
      min-width: 1280px;
      padding-bottom: 40px;
      .left, .right {
        margin: 0 15px;
      }
    }
    .short-list {
      margin: 0 32px;
    }
}
</style>