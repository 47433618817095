<template>
  <el-dialog
    :visible.sync="visible"
    title="微信扫二维码进入"
    center
    destroy-on-close
    width="713px"
    class="qrcode-dialog"
    :lock-scroll="false"
    @close="onClose"
  >
    <img v-show="qrcodeSrc" :src="qrcodeSrc" class="qrcode" draggable="false" alt="" />
    <span v-if="title" slot="footer" class="title">{{ title }}</span>
  </el-dialog>
</template>

<script>
import imgSrc from "@/utils/bigDataIMG.js"
const bigDataIMG = imgSrc.data()

export default {
  name: 'QrcodeDialog',

  props: {
    // 由父组件控制的对话框可见性
    dialogVisible: {
      type: Boolean,
      default: false
    },
    // 二维码类型(可选值: `shop`, `app`)
    type: {
      type: String,
      validator(value) {
        // The value must match one of these strings
        return ['', 'shop', 'app', 'exhibitor'].includes(value)
      }
    }
  },

  data() {
    return {
      // 组件自身控制的对话框可见性
      visible: false,
      title: '',
      // 二维码url
      qrcodeSrc: ''
      
    }
  },

  watch: {
    dialogVisible(newValue, oldValue) {
      if(newValue !== oldValue) {
        this.visible = newValue
        newValue && this.init()
      }
    }
  },

  methods: {
    // 对应类型的对话框数据初始化
    init() {
      const { type } = this
      switch(type) {
        case 'shop': {
          this.title = '地被商城'
          this.qrcodeSrc = bigDataIMG.qrcode_shop
          break
        }
        case 'app': {
          this.title = '供采系统'
          this.qrcodeSrc = bigDataIMG.qrcode_app
          break
        }
        case 'exhibitor': {
          this.title = '云展会展商介绍'
          this.qrcodeSrc = bigDataIMG.qrcode_exhibitor
          break
        }
        default: {
          break
        }
      }
    },

    /* 对话框关闭事件监听处理 */
    onClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.qrcode-dialog {
  ::v-deep .el-dialog__body {
    text-align: center;
  }
  ::v-deep .el-dialog__footer {
    padding-bottom: 45px;
  }
  .qrcode {
    width: 318px;
    height: 318px;
    margin: 10px;
  }
  .title {
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
}
</style>