<template>
  <div class="cloud-news-card" :style="{ 'width': width }">
    <div v-if="isShowCover && news.newsCover" class="cover">
      <img :src="news.newsCover" alt="" @click="toNewsDetailPage" />
    </div>
    <div class="main">
      <div class="title" @click="toNewsDetailPage">{{ news.newsTitle }}</div>
      <div class="abstract">{{ news.newsAbstract }}</div>
      <div class="bottom">
        <div class="left">
          <span class="time">{{ news.publishedTimeTxt }}</span>
          <span class="view">{{ news.viewCountTxt }}</span>
        </div>
        <div class="right">
          <div class="button" @click="toNewsDetailPage">
            <span class="text">查看详情</span>
            <span class="icon">
              <i class="el-icon-arrow-right" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CloudNewsCard',

  props: {
    // 云资讯详情数据
    news: {
      type: Object,
      required: true
    },
    // 是否需要显示封面
    isShowCover: {
      type: Boolean,
      default: true
    },
    // 新闻卡片宽度
    width: {
      type: String,
      default: '600px'
    }
  },
  
  data() {
    return {

    }
  },

  methods: {
    /* 跳转到云资讯详情页 */
    toNewsDetailPage() {
      const routeData = this.$router.resolve({
        name: 'CloudNewsDetail',
        query: { article: this.news.id }
      })
      window.open(routeData.href)
    }
  }
}
</script>

<style lang="less" scoped>
.cloud-news-card {
  width: 600px;
  margin-bottom: 30px;
  .cover {
    width: 100%;
    height: 256px;
    margin-bottom: 30px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .main {
    padding: 0 2px;
    .title {
      height: 22px;
      margin-bottom: 10px;
      padding: 2px 0;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; 
      font-size: 16px;
      font-weight: 600;
      color: #333;
      &:hover {
        cursor: pointer;
        color: #177D6A;
      }
    }
    .abstract {
      margin-bottom: 10px;
      height: 40px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 400;
      color: #666;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #999;
      padding-bottom: 5px;
      border-bottom: 2px solid #e9e9e9;
      .left {
        .time {
          margin-right: 20px;
        }
      }
      .right {
        .button {
          .text {
            margin-right: 5px;
          }
          .icon {
            display: inline-block;
            width: 14px;
            height: 14px;
            line-height: 14px;
            text-align: center;
            border-radius: 100%;
            background: #e9e9e9;
          }
          &:hover {
            cursor: pointer;
            color: #177D6A;
            .icon {
              color: #fff;
              background: #177D6A;
            }
          }
        }
      }
    }
  }
  .link {
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
    &:hover {
      cursor: pointer;
      box-shadow: inset 600px 0 0 0 #8da2c8;
      color: #fff;
    }
  }
}
</style>